<template>
  <v-container fluid>
    <record-lock
      :uuid="$route.params.uuid"
      :record-lock="lab_result.record_lock"
      @update:lock="lab_result.record_lock = $event"
    />
    <lab-result-form
      :lab-result-loading="loading"
      :lab-result="lab_result"
      :title="false"
      :upload="false"
      :more="false"
      edit
      @lab-result-added="$dialog.info({
        title: `${$tc('Lab Result', 1)} ${lab_result.external_id}`,
        text: `${$tc('Lab Result', 1)} ${$t('updated')}. ${$t('It may take a few moments for the changes to reflect in the system')}.`,
        actions: [{
          text: 'Ok',
          handle: () => $router.push({ name: 'Lab Results' }),
        }],
      })"
      @cancel="$router.push({ name: 'Lab Results' })"
    />
  </v-container>
</template>
<script>
export default {
  components: {
    LabResultForm: () => import('@/components/lab_result/LabResultForm.vue'),
    RecordLock: () => import('@/components/lab_result/RecordLock.vue'),
  },
  data: () => ({
    lab_result: {},
    loading: false,
  }),
  created () {
    this.fetchLabResult()
  },
  methods: {
    fetchLabResult () {
      this.loading = true
      const promise = this.axios.get('lab_results/' + this.$route.params.uuid + '/edit')

      return promise.then((response) => {
        this.lab_result = response.data
      })
        .catch(error => {
          // If 404 redirect back to lab results page
          if (error.response?.status === 404) {
            this.$router.push({ name: 'Lab Results' })
          } else if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>
