var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('record-lock',{attrs:{"uuid":_vm.$route.params.uuid,"record-lock":_vm.lab_result.record_lock},on:{"update:lock":function($event){_vm.lab_result.record_lock = $event}}}),_c('lab-result-form',{attrs:{"lab-result-loading":_vm.loading,"lab-result":_vm.lab_result,"title":false,"upload":false,"more":false,"edit":""},on:{"lab-result-added":function($event){_vm.$dialog.info({
      title: `${_vm.$tc('Lab Result', 1)} ${_vm.lab_result.external_id}`,
      text: `${_vm.$tc('Lab Result', 1)} ${_vm.$t('updated')}. ${_vm.$t('It may take a few moments for the changes to reflect in the system')}.`,
      actions: [{
        text: 'Ok',
        handle: () => _vm.$router.push({ name: 'Lab Results' }),
      }],
    })},"cancel":function($event){return _vm.$router.push({ name: 'Lab Results' })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }